<script>
export default {
  data() {
    return {
      valid: true,
      loading: false,
      dialog: false,
      dialogDelete: false,
      errors: {},
      actionMethod: '',
      selectedId: 0,
    }
  },

  methods: {
    createDialog() {
      this.actionMethod = 'create'
      this.dialog = true
    },

    editDialog(field) {
      this.actionMethod = 'edit'
      this.selectedId = field.id

      for (const prop in this.dialogData) {
        this.dialogData[prop] = field[prop]
      }

      this.dialog = true
    },

    deleteDialog(field) {
      this.dialogDelete = true

      this.selectedId = field.id
    },
  },
}
</script>
